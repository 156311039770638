<template>
  <div id="capabilities-page">
    <div id="steps" class="d-inline-flex w-100 justify-content-between mb-1">
      <div id="previous-step">
        <b-button variant="outline-primary" size="sm">
          <router-link :to="{ name: 'model_canvas' }">
            Business Canvas
          </router-link>
        </b-button>
        <span class="font-medium-1 ml-1">
          <feather-icon
            icon="ChevronsLeftIcon"
          />
          Previous step
        </span>
      </div>

      <div id="next-steps">
        <span class="font-medium-1 mr-1">
          Next steps
          <feather-icon
            icon="ChevronsRightIcon"
          />
        </span>
        <b-button class="ml-50" variant="outline-primary" size="sm">
          <router-link :to="{ name: 'model_ideas' }">
            Gather Ideas
          </router-link>
        </b-button>
        <b-button class="ml-50" variant="outline-primary" size="sm">
          <router-link :to="{ name: 'domain_analysis' }">
            Define Functions
          </router-link>
        </b-button>
      </div>
    </div>

    <b-card id="capabilities" no-body>
      <b-card-body>
        <table-header
          label="Capabilities"
          modal="create-capability-modal"
        />
      </b-card-body>

      <b-table
        striped
        hover
        show-empty
        :items="capabilities"
        :fields="tblFieldsCapabilities"
        :filter="criteria"
        :filter-function="filterTable"
      >
        <template v-slot:cell(name)="data">
          <b>{{ data.item.name }}</b>
        </template>
        <template v-slot:cell(description)="data">
          {{ data.item.description }}
        </template>
        <template v-slot:cell(current)="data">
          {{ data.item.current }}
        </template>
        <template v-slot:cell(gaps)="data">
          <span v-html="data.item.gaps" />
        </template>
        <template v-slot:cell(needs)="data">
          <b-table-simple hover responsive>
            <div>
              <b-tr v-for="need in data.item.needs" :key="need.id">
                <b-td>
                  {{ need.description }}
                  <br>
                  <b>Related FICs:</b>
                  <ul v-if="need.fics.length > 0">
                    <li v-for="fic in need.fics" :key="fic.id">
                      {{ fic.name }}
                    </li>
                  </ul>
                  <div v-else>
                    <small><i>No related FICS</i></small>
                  </div>
                  <b>Related Objectives:</b>
                  <ul v-if="need.objectives.length > 0">
                    <li v-for="obj in need.objectives" :key="obj.id">
                      {{ obj.name }}
                    </li>
                  </ul>
                  <div v-else>
                    <small><i>No related Objectives</i></small>
                  </div>

                </b-td>
              </b-tr>
            </div>
          </b-table-simple>
        </template>

        <template v-slot:cell(actions)="data">
          <div class="text-nowrap">
            <b-button variant="flat-secondary" size="sm" class="btn-icon" @click="updateCapability(data)">
              <feather-icon icon="EditIcon" />
            </b-button>
            <b-button variant="flat-danger" size="sm" class="btn-icon" @click="deleteCapability(data)">
              <feather-icon icon="TrashIcon" />
            </b-button>
          </div>
        </template>
      </b-table>
    </b-card>

    <div id="modals">
      <CreateCapabilityModal />
      <DeleteCapabilityModal />
      <UpdateCapabilityModal />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import {
  BButton, BTable,
} from 'bootstrap-vue'
import TableHeader from '@/components/BRSCanvas/TableHeader.vue'
import CreateCapabilityModal from '@/components/BRSCanvas/Modals/Create/CreateCapability.vue'
import DeleteCapabilityModal from '@/components/BRSCanvas/Modals/Delete/DeleteCapability.vue'
import UpdateCapabilityModal from '@/components/BRSCanvas/Modals/Update/UpdateCapability.vue'

export default {
  components: {
    BButton,
    BTable,
    TableHeader,
    CreateCapabilityModal,
    DeleteCapabilityModal,
    UpdateCapabilityModal,
  },
  data: () => ({
    criteria: 'True',
    tblFieldsCapabilities: [
      {
        key: 'name',
        label: 'Name',
        sortable: true,
        thStyle: { width: '10%' },
      },
      {
        key: 'description',
        label: 'Description',
        sortable: true,
        thStyle: { width: '20%' },
      },
      {
        key: 'current',
        label: 'Current State',
        sortable: true,
        thStyle: { width: '25%' },
      },
      {
        key: 'gaps',
        label: 'Gaps',
        thStyle: { width: '20%' },
      },
      {
        key: 'needs',
        label: 'Needs/Improvements',
        thStyle: { width: '20%' },
      },
      {
        key: 'actions',
        label: 'Actions',
        thStyle: {
          width: '9rem',
        },
      },
    ],
    selectedCapabilityId: '',
  }),
  computed: {
    ...mapState({
      capabilities: state => state.canvas.capabilities,
      props: state => state.model.lookup,
    }),
  },
  mounted() {
    this.fetchCapabilities()
  },
  beforeDestroy() {
    this.$store.dispatch('canvas/clearCanvas')
  },
  methods: {
    fetchCapabilities() {
      this.$store.dispatch('canvas/fetchCapabilities').then(() => {
        console.debug('Capabilities loaded: ', this.capabilities)
      })
    },
    filterTable(row, criteria) {
      return (row.abstract !== criteria)
    },
    updateCapability(data) {
      this.$store
        .dispatch('canvas/selectCapability', data.item.id)
        .then(() => this.$bvModal.show('update-capability-modal'))
    },
    deleteCapability(data) {
      this.$store
        .dispatch('canvas/selectCapability', data.item.id)
        .then(() => this.$bvModal.show('delete-capability-modal'))
    },
  },
}
</script>
